import React from "react"
import { graphql } from "gatsby"

const PageRedirect = ({ data }) => {
   let redirectData = data.wordpressWpMkqr.acf

   let redirectTo = `${redirectData.qr_destination}?utm_source=${redirectData.qr_source}&utm_medium=${redirectData.qr_medium}&utm_campaign=${redirectData.qr_campaign}`

   if (typeof window !== "undefined") {
      window.location.replace(redirectTo)
   }

   return (
      <div style={{ padding: "1rem" }}>
         <p>Vous allez être redirigé vers la page...</p>
         <p style={{ marginTop: ".5rem" }}>
            Si la redirection ne fonctionne pas, vous pouvez cliquer sur{" "}
            <a href={redirectTo}>ce lien</a>
         </p>
      </div>
   )
}

export default PageRedirect

export const query = graphql`
   query($id: Int!) {
      wordpressWpMkqr(wordpress_id: { eq: $id }) {
         acf {
            qr_campaign
            qr_destination
            qr_medium
            qr_source
         }
      }
   }
`
